.audio-container {
  display: flex;
  //justify-content: center;
  padding-top: 2rem;
}

// .audio-player {
//   margin-top: 25%;
// }

.responsive-audio-container {
  width: 100%;
  display: flex;
}

.responsive-audio {
  width: 100%;
  height: 200px;
  @media screen and (min-width: 1075px) and (max-width: 1240px) {
    height: 150px;
  }
  @media screen and (max-width: 733px) {
    height: 140px;
  }

  @media screen and (max-width: 570px) {
    height: 100px;
  }
  @media screen and (max-width: 375px) {
    height: 80px;
  }
}

// .player-wrapper {
//   position: relative;
//   padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
//   img {
//     position: absolute;
//     background-position: right;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//   }
// }

// .react-player {
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// audio::-webkit-media-controls-panel,
// ::-webkit-media-controls-enclosure {
//   background-color: rgba(220, 0, 50, 0.7%);
//   border-radius: 0px;
// }
