.responsive-video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 1200px) {
    width: 100%;
    height: 100%;
  }
}

.video-player {
  padding-top: 2rem;
}

.details-container {
  margin-top: 2rem;
  color: white;
  padding-left: 0 !important;
}

.date {
  margin-bottom: 0.25rem !important;
  @media screen and (max-width: 600px) {
    display: none;
  }
}

.length {
  @media screen and (max-width: 600px) {
    text-align: end;
  }
}

.details {
  width: 100%;
  div {
    display: flex;
    width: 100%;
    align-items: center;

    .date-mobile {
      margin-left: auto;
    }
  }
  @media screen and (min-width: 600px) {
    div {
      .date-mobile {
        display: none;
      }
    }
  }
}

.description-row {
  ul {
    margin: 0 0px 20px 30px;
  }
}

// .player {
//   max-width: unset !important;
// }

// .wistia_responsive_padding {
//   padding: 66.67% 0 0 0;
//   position: relative;
// }

// .wistia_responsive_wrapper {
//   height: 100%;
//   left: 0;
//   position: absolute;
//   top: 0;
//   width: 100%;
// }

// .wistia_embed {
//   height: 100%;
//   position: relative;
//   width: 100%;
// }

// .wistia_swatch {
//   height: 100%;
//   left: 0;
//   opacity: 0;
//   overflow: hidden;
//   position: absolute;
//   top: 0;
//   transition: opacity 200ms;
//   width: 100%;
//   max-height: 500px;

//   img {
//     filter: blur(5px);
//     height: 100%;
//     object-fit: contain;
//     width: 100%;
//   }
//}
