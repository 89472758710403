@import "~bootstrap/scss/bootstrap";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: black;
  margin-bottom: 5rem;

  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
}

.t300 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.87);
}

.t200 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: rgba(255, 255, 255, 0.67);
}

.t100 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  /* white-medium */

  color: rgba(255, 255, 255, 0.67);
}

.h100 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
}

.t800 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 18px;
  }
}

.t400 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 14px;
  }
}

.tags-f {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
}

.small-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.87);
  @media screen and (max-width: 600px) {
    font-size: 10px;
    line-height: 12px;
  }
}

.share-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  div > p {
    margin-bottom: 0.2rem;
  }

  button {
    margin-left: 5px;
  }
}
