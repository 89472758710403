@import "./variables.scss";
@import "~bootstrap/scss/bootstrap";

.main-container {
  overflow-x: hidden;
}

nav {
  padding-left: 0.5em;
  padding-top: 1.5rem;
  @include media-breakpoint-up(sm) {
    padding-left: 1.5rem;
    padding-top: 2rem;
  }
}

.logo-img {
  //height: 40px;
  height: auto;
  width: 126px;
  @include media-breakpoint-up(sm) {
    //height: 48px;
    height: auto;
    width: 151px;
  }
}

.video-play-row {
  padding-left: 0.5rem;
  @include media-breakpoint-up(sm) {
    padding-left: 1.5rem;
  }
}

.line {
  margin-left: 2rem;
  margin-top: 2rem;
  width: 90vw;
  height: 2px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(142, 0, 0, 1) 48%,
    rgba(0, 0, 0, 1) 100%
  );
  @media screen and (min-width: 1200px) {
    margin-bottom: 2rem;
    margin-top: -2rem;
    height: 700px;
    width: 2px;
    background: linear-gradient(
      45deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(142, 0, 0, 1) 48%,
      rgba(0, 0, 0, 1) 100%
    );
  }

  @media screen and (min-width: 1500px) {
    height: 900px;
  }
}

.line-container {
  margin: auto 0;
}

.content-type {
  display: flex;
  color: #808285;
  font-size: 18px;
  padding-bottom: 1rem;

  @media screen and (max-width: 990px) {
    margin-top: 2rem;
  }

  .watch-container {
    max-width: 200px;
    display: flex;
    width: 50%;
    cursor: pointer;
    border-bottom: 2px solid #dc0032;
    margin-bottom: -2px;
  }

  .listen-container {
    max-width: 200px;
    display: flex;
    width: 50%;
    margin-left: 28px;
    margin-right: auto;
    cursor: pointer;
  }
}

.watch-logo {
  padding-right: 15px;
}

.watch {
  padding-top: 15px;
}

.listen-logo {
  padding-right: 15px;
}

.listen {
  padding-top: 15px;
}

.media {
  margin-bottom: 10px;
  cursor: pointer;
}

.list-unstyled {
  height: 420px;
  overflow-y: hidden;

  &:hover {
    overflow-y: scroll;
  }

  @media screen and (max-width: 1075px) {
    display: flex;
    flex-wrap: wrap;

    .media {
      display: block;
    }
  }
  @media screen and (max-width: 600px) {
    .media {
      display: flex;
    }
    height: 270px;
  }
}

.content {
  padding-left: unset;

  @media screen and (min-width: 990px) {
    padding-left: 0 !important;
  }
  @media screen and (min-width: 1400px) {
    padding-left: unset;
  }
}

.tags-container {
  display: flex;
  padding-bottom: 1rem;
  overflow-x: hidden;

  &:hover {
    overflow-x: scroll;
  }
}

.ForcedLoginBackground {
  background-color: #ffffff;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ForcedLoginContainer {
  align-self: center;
  text-align: center;
}
