.tag {
  margin-right: 10px;
  padding: 0.3rem 0.5rem;
  border-radius: 50px;
  background-color: transparent;
  border: 1px white solid;
  color: white;
  min-width: 100px;
  display: block;

  &:focus {
    outline: unset;
  }
}

.active {
  background-color: white;
  color: black;
}

button:active {
  border-style: outset;
}

.tag-large {
  min-width: 120px;
}

.tag-Extralarge {
  min-width: 165px;
}
