.card-text {
  height: 96px;
  overflow-y: hidden;
  color: #eee;
  max-width: 205px;

  @media screen and (max-width: 600px) {
    height: 55px;
    //width: 100%;
  }
}

.card-title {
  color: #ffffff !important;
  margin-bottom: 0.5rem !important;
  width: 205px;

  @media screen and (max-width: 1075px) {
    margin-top: 0.5rem;
  }
  @media screen and (max-width: 600px) {
    margin-top: unset;
    width: unset;
  }
}

.card-image {
  @media screen and (max-width: 600px) {
    width: 112px;
    height: 70px;
  }
}

.thumbnail {
  position: relative;

  .play-button {
    position: absolute;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 600px) {
      height: auto;
      width: 25%;
      left: 45%;
    }
  }
}

.podcast-img-media {
  @media screen and (max-width: 600px) {
    height: 115px;
    width: 120px;
  }
}
